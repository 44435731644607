import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {
    url: "womens-health-impact/fda-validation-study",
    handle: "FDA Validation Study",
  },
]

const FdaValidationStudy = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-3.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="FDA Validation Study - ExCellerator" />
          <SubpageBanner image={background} heading="FDA Validation Study" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  copy="Immediately following the introduction of the ThinPrep Pap Test system, clients began to experience and complain about results of specimen inadequacy from samples they contended were quality collections. We experienced clients actually switching back to the conventional method out of frustration. We knew that the new system was better overall, so we set out to find out what was causing the issue."
                >
                  <p>2 of 108 cases were resulted UNSATISFACTORY on standard collection…
Both were resulted SATISFACTORY on residuum captured via exCellerator
</p>
                  <h3>Study Design</h3>
                  <ul>
                    <li>Vial 1: Standard Collection – Patient Care Sample.</li>
                    <li>Vial 2: Discarded Sample captured from brush via exCellerator.</li>
                    <li>A Validation Study was conducted to show:</li>
                    <li>That sample remains on the cytobrush following standard “rinsing” and is therefore thrown away.</li>
                    <li>That the exCellerator device can safely and effectively aid in transferring the sample into ThinPrep liquid-based cytology medium.</li>
                    <li>That sufficient residual sample can be transferred in order to create an “adequate” ThinPrep Pap Slide</li>
                  </ul>
                  <h3>Study Determinations:</h3>
                  <ul>
                    <li>There is residual sample left on the cytobrush.</li>
                    <li>It can be safely and effectively transferred.</li>
                    <li>There is sufficient quantity and quality to create an adequate ThinPrep slide for evaluation.</li>
                    <li>94% of residual samples yielded adequate/satisfactory preparations.</li>
                  </ul>
                  <p>Results/Conclusions:</p>
                  <ul>
                    <li>UNSAT Rate was 0.5% versus 1.3% (CAP National Data)</li>
                    <li>LSIL or Greater Diagnosis rate was 6.1% with exCellerator versus 4.1% without, in the same patient population.</li>
                    <li>Diagnosis of HSIL or ASC-H rate was 1.3% with exCellerator versus 0.8% without, in the same patient population.</li>
                  </ul>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default FdaValidationStudy
